.home{
  height: 100%;
  background: url("../img/defaultBG.jpg");
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 767px) {
   // background-size: 100% auto;
    background-size: cover;
  }
  .gradient{
    height: 50%;
  }
  .logo{
    position: absolute;
    top: 2rem;
    left: 1rem;
    width: 50vw;
    @media (min-width: 767px) {
      left: 2rem;
      height: 70px;
      width: auto;
    }
  }
}