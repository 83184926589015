@import '../theme';
.ballsWrapper{
  margin-left: 5%;
  display: inline-block;
  float: left;
  .pageBall{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $panelColor;
    margin-left: 8px;
    display: inline-block;
    float: left;
    opacity: 0.2;
    margin-top: 13px;
  }
  .pageBallSelected{
    @extend .pageBall;
    opacity: 1;
  }
}
