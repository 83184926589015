.App {
  text-align: center;
  width: 100vw;
  overflow: hidden;
}
#menu-{
  z-index: 1401!important;
}

#menu-shopID{
  z-index: 1401!important;
}
.rccs{
  margin-bottom: 1rem!important;
}